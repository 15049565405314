import { styled } from '@mui/system'
import Typography from '../../../atoms/typography'
import Button from '../../button'
import BHLink from '../../link'

const shouldForwardProp = (prop) => !['open', 'anchor'].includes(prop)

const NavMenu = styled('nav', { shouldForwardProp })`
	display: flex;
	flex-direction: column;
`

const NavLinkStyled = styled(BHLink)`
	font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;

	.MuiSvgIcon-root {
		font-size: 22px;
	}
`

const NavItem = styled(Typography)`
	margin-bottom: var(--spacing-3);
`

const NavSubMenu = styled('div', { shouldForwardProp })`
	position: fixed;
	top: 0;
	width: 100%;
	background: ${({ theme }) => theme.palette.white.main};
	height: 100%;
	padding: 18px;
	will-change: transition;
	z-index: 2;
	${({ theme }) => theme.scrollbar};
	${({ anchor }) =>
		anchor === 'left'
			? `
					left: -100%;
					transition: left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
			  `
			: `
					right: -100%;
					transition: right 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
			  `}
	&.nav-open {
		${({ anchor }) => (anchor === 'left' ? 'left: 0%;' : 'right: 0%;')}
		&.nav-moved {
			${({ anchor }) => (anchor === 'left' ? 'left: 100%;' : 'right: 100%;')}
		}
	}
`

const ParentNavTitle = styled(BHLink)`
	margin: 30px auto 16px 0;
	display: block;
	min-height: 20px;
`

const SubNavLinkStyled = styled(BHLink)`
	padding: var(--spacing-1) 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	&.submenu-item-view-all {
		font-weight: ${({ theme }) => theme.typography.fontWeightBold};
	}
	.MuiSvgIcon-root {
		font-size: ${({ theme }) => theme.typography.pxToRem(22)};
	}
`

const SubContainerNavItem = styled('div')``

const SubNavItem = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
`

const BackButton = styled(Button)`
	margin: 0;
	border: 0;
	font-family: ${({ theme }) => theme.typography.fontFamily};
	font-weight: normal;
	text-transform: none;
	padding: 0;
	min-height: 0;
	.MuiButton-startIcon > *:nth-of-type(1) {
		font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	}
	&:after {
		display: none;
	}
`

const SubNavCmsBlock = styled('div')`
	padding: 16px 24px;

	img {
		display: block;
		width: 100%;
	}
`

export {
	BackButton,
	NavMenu,
	NavItem,
	NavLinkStyled,
	NavSubMenu,
	SubNavItem,
	SubNavLinkStyled,
	SubContainerNavItem,
	SubNavCmsBlock,
	ParentNavTitle,
}
